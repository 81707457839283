<template>
  <div class="home">
    <CommonHeader title="设置委托提货人" />
    <div class="t ft14 fw500">提货人姓名 <span
        class="ft12"
        :style="{ color: colorData.specialColor }"
      >(与身份证姓名一致)</span></div>
    <div class="inp flex align-items-c">
      <input class="flex-one" type="text" v-model="name">
    </div>
    <div class="t ft14 fw500">提货人身份证号</div>
    <div class="inp flex align-items-c">
      <input class="flex-one" type="text" v-model="idCode">
    </div>
    <div class="t ft14 fw500">提货人手机号</div>
    <div class="inp flex align-items-c">
      <input class="flex-one" type="text" v-model="phone">
    </div>
    <div class="btn" :style="rightBtnStyle">确认</div>
  </div>
</template>

<script>
import { ref, onBeforeMount, computed, watch } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    const name = ref('');
    const idCode = ref('');
    const phone = ref('');

    const colorData = computed(() => {
      return store.getters.color_data ? store.getters.color_data : {};
    });
    // 右按钮样式
    const rightBtnStyle = computed(() => {
      return store.getters.right_button_style
        ? store.getters.right_button_style
        : {};
    });

    return {
      colorData,
      rightBtnStyle,
      name,
      idCode,
      phone
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  background: #f7f7f7;
  .t {
    padding: 64px 32px 32px 32px;

    span {
      font-weight: 400;
    }
  }

  .inp {
    height: 96px;
    background: #ffffff;
    border-radius: 8px;
    border: 2px solid #eeeeee;
    margin: 0 32px;
    box-sizing: border-box;
    padding: 0 20px;

    input{
      flex: 1;
      height: 92px;
      line-height: 92px;
      font-size: 26px;
      border: none;
      background: none;
    }
  }

  .btn{
    position: fixed;
    bottom: 68px;
    left: 32px;
    right: 32px;
    text-align: center;
    line-height: 96px;
    height: 96px;
  }
}
</style>